import { useTranslation } from 'react-i18next';
import React from 'react';
import useLocalStorage from './hooks/use-localstorage';
import i18n from './i18n';

import { Footer, Card, Header, Community, ContactForm, Porfolio, Brand } from './containers';
import { Navbar } from './components';

import './App.css';


function App() {

return (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
      <Header />
    </div>
    <Brand />
    <Card />
    <Community />
    <ContactForm />
    <Footer />
  </div>
  );
}
export default App;
