import React from 'react';
import { useTranslation } from 'react-i18next';
import CardNft from '../../components/card/CardNft';
import { investor, member, investorGif, memberGif, imgMember, imgInvestor } from './imports';
import './card.css';
import BlockName from '../../components/block/block';

const memberPlus = [
  {
    text: 'm_feature0',
  },
  {
    text: 'm_feature3',
  },
  {
    text: 'm_feature1',
  },
  {
    text: 'm_feature2',
  },

];
const investorPlus = [
  {
    text: 'i_feature0',
  },
  {
    text: 'i_feature1',
  },
  {
    text: 'i_feature2',
  },
  {
    text: 'i_feature3',
  },
  {
    text: 'i_feature4',
  },
];

const Card = () => {
  const { t } = useTranslation();
return(
  <div className="mry__blog section__padding" id="access">
    <BlockName text={t("block_get_access")} />
    <div className="mry__blog-container">
	<div></div>
      <div className="mry__blog-container_groupA moveleft">
        <CardNft img={imgInvestor} imgUrl={investor} imgUrlGif={investorGif} name="Member" text={t("member_pay")} text_info={t("member_info")} price="1000 USDT" option={memberPlus} />
      </div>
	<div></div>
    </div>
  </div>
);
}
export default Card;
