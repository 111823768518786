import { useTranslation } from 'react-i18next';
import React from 'react';

import './header.css';

const Header = () => {
  const { t } = useTranslation();
return(
  <div className="mry__header section__padding">
    <div className="mry__header-content">
      <h1 className="gradient__text">{t("brand")}</h1>
      <p>{t("descs1")}</p>
      <p>{t("descs2")}</p>
      <div className="mry__header-content__input">
        <a target="_blank" rel="noreferrer" href="https://mry.gitbook.io/mry">
          <button type="button">{t("btn_read_more")}</button>
        </a>
      </div>
    </div>
  </div>
);
}
export default Header;
