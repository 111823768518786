import React from 'react';
import './footer.css';
import { Social } from '../../components';

const Footer = () => (
  <div className="mry__footer">
    <div className="mry__footer-copyright">
      <p>Copyright © MRY 2022</p>
    </div>
    <div className="mry__footer-links">
      
    </div>
    <div className="mry__footer-privacy">
      <Social />
    </div>
  </div>
);

export default Footer;
