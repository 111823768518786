import React from 'react';
import './button.css';

const Button = ({ text, link }) => (
  <div className="mry__btn">
    <a href={link} target="_blank" rel="noreferrer">
      <button type="button">{text}</button>
    </a>
  </div>
);

export default Button;
