import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { FaGlobe } from 'react-icons/fa';
import logo from '../../assets/logo_png.png';
import './navbar.css';
import Social from '../social/Social';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../../hooks/use-localstorage';
import i18n from '../../i18n';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { t } = useTranslation();
  const [language, setLanguage] = useLocalStorage('language', 'ru');
  const handleLenguageChange = () => {
    if (language === 'en') {
        i18n.changeLanguage('ru');
        setLanguage('ru');
    } else if (language === 'ru') {
        i18n.changeLanguage('en');
        setLanguage('en');
    }
  };

  return (
    <div className="mry__navbar">
      <div className="mry__navbar-links">
        <div className="mry__navbar-links_logo">
          <a href="/">
            <img src={logo} />
          </a>
        </div>
        <div className="mry__navbar-links_container">
          <p><a href="#about">{t("nav_about")}</a></p>
          <p><a href="#access">{t("nav_access")}</a></p>
          <p><a href="#community">{t("nav_community")}</a></p>
          <p><a target="_blank" rel="noreferrer" href="https://mry-vc.gitbook.io/mry">{t("nav_docs")}</a></p>
          <p><a href="#contact">{t("nav_contact")}</a></p>
        </div>
        <div className="block_soc">
          <Social />
        </div>
      </div>
      <div className="mry__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="mry__navbar-menu_container scale-up-center">
          <div className="mry__navbar-menu_container-links">
            <p><a href="#about">{t("nav_about")}</a></p>
            <p><a href="#access">{t("nav_access")}</a></p>
            <p><a href="#community">{t("nav_community")}</a></p>
            <p><a target="_blank" rel="noreferrer" href="https://mry-vc.gitbook.io/mry">{t("nav_docs")}</a></p>
            <p><a href="#contact">{t("nav_contact")}</a></p>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
