import React from 'react';
import './social.css';
import { FaTwitter, FaDiscord, FaMediumM, FaTelegramPlane } from 'react-icons/fa';

const Social = () => (
  <div className="mry__social">
    <div className="mry__social-links">
      <div className="social_icons"><a href="https://twitter.com/MRYDegens"> <FaTwitter size={19} /> </a>
      </div>
      <div className="social_icons"><a href="https://discord.gg/xEBUPHQFYa"> <FaDiscord size={19} /></a>
      </div>
    </div>
  </div>
);

export default Social;
