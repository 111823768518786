import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import contactImage from '../../assets/bg_contact_l.png';
import './contact.css';
import BlockName from '../../components/block/block';
import { Botton } from '../../components';

const FORM_ENDPOINT = '&lsquo;';

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <>
        <h2>Thank you!</h2>
        <div>We be in touch soon.</div>
      </>
    );
  }

  return (
    <form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="POST"
      target="_blank"
    >
      <div className="section__padding" id="contact">
        <BlockName text={t("block_contact")} />
        <div className="mry__contact" id="contact">
          <div className="mry__contact-image">
            <img src={contactImage} alt="contact" />
          </div>
          <div className="mry__contact-content">
            <input type="text" id="fname" name="firstname" placeholder={t("firstname")} />
            <input type="text" id="email" name="email" placeholder={t("email")} />
            <textarea id="subject" name="subject" placeholder={t("text_m")} />
            <Botton text={t("btn_send")} />
          </div>
        </div>
      </div>
    </form>
  );
};
export default ContactForm;
