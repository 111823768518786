import React from 'react';
import { useTranslation } from 'react-i18next';
import './brand.css';
import BlockName from '../../components/block/block';

const Brand = () => {
  const { t } = useTranslation();
  return (
    <div className="mry__brand section__padding">
      <BlockName text={t("block_about_mry")} />
      <div className="text_about" id="about">
        <h3>{t("about_title")}</h3>
        <p>{t("abouts")}</p>
        <p>{t("abouts1")}</p>
        <p>{t("abouts2")}</p>
        <p>{t("abouts3")}</p>
        <p>{t("abouts4")}</p>
      </div>
    </div>
    );
}

export default Brand;
