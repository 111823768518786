import React from 'react';
import Mark from '../mark/mark';
import './nft.css';

const Nftfeature = ({ text }) => (
  <div className="mry__features-container__nft">
    <div className="mry__features-container__nft-mark"><Mark />
    </div>
    <div className="mry__features-container_nft-text">
      <p>{text}</p>
    </div>
  </div>
);
export default Nftfeature;
