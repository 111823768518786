import React from 'react';
import { useTranslation } from 'react-i18next';
import './cardnft.css';
import HoverVideoPlayer from 'react-hover-video-player';
import Nftfeature from '../nftfeatures/Nft';
import Button from '../button/Button';

const CardNft = ({ img, imgUrl, name, text, option, text_info, price }) => {
  const { t } = useTranslation();
  return(
  <div className="mry__blog-container_article">
    <div className="mry__blog-container_article-image">
      <h3>{name}</h3>
      <HoverVideoPlayer videoSrc={imgUrl} pausedOverlay={<img src={img} alt="loading..." className="img_cover" />} />
    </div>
    <div className="mry__blog-container_article-content">
      <div>
        <div className="q_nft_block">
          <div className="q_nft">?
            <span className="q_info">{text_info}</span>
          </div>
        </div>
        <div className="price">{price}</div>
        <h4>{text}</h4>
        {option.map((item) => (<Nftfeature text={ t(item.text)} />
        ))}
      </div>
      <Button text={t("btn_buy")} link="http://mint.mry.vc" />
    </div>
  </div>
  );
}
export default CardNft;
