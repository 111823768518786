import React from 'react';
import { useTranslation } from 'react-i18next';
import './community.css';
import ReadMarket from '../../assets/read_market.svg';
import Communicate from '../../assets/communicate.svg';
import Learn from '../../assets/learn.svg';
import Receive from '../../assets/receive.svg';
import Find from '../../assets/find.svg';
import { MarkG } from '../../components';
import BlockName from '../../components/block/block';

const Community = () => {
  const { t } = useTranslation();
  return(
  <div className="section__padding" id="community">
    <BlockName text={t("block_community")} />
    <div className="mry__community">
      <div className="community_divA">
        <div className="community_group">
          <img src={ReadMarket} alt="" />
          <p>{t("c_feature1")}</p>
        </div>
        <div className="community_group">
          <img src={Communicate} alt="" />
          <p>{t("c_feature2")}</p>
        </div>
        <div className="community_group">
          <img src={Learn} alt="" />
          <p>{t("c_feature3")}</p>
        </div>
        <div className="community_group">
          <img src={Receive} alt="" />
          <p>{t("c_feature4")}</p>
        </div>
        <div className="community_group end_block">
          <img src={Find} alt="" />
          <p>{t("c_feature5")}</p>
        </div>
      </div>
      <div className="community_divB">
        <div className="community_info">
          <MarkG />
          <div className="tooltip">{t("chat_general")}
            <span className="tooltiptext">{t("general_info")}</span>
          </div>
        </div>
        <div className="community_info">
          <MarkG />
          <div className="tooltip">{t("chat_trading")}
            <span className="tooltiptext">{t("trading_info")}</span>
          </div>
        </div>
        <div className="community_info">
          <MarkG />
          <div className="tooltip">{t("chat_nft")}
            <span className="tooltiptext">{t("nft_info")}</span>
          </div>
        </div>
        <div className="community_info">
          <MarkG />
          <div className="tooltip">{t("chat_defi")}
            <span className="tooltiptext">{t("defi_info")}</span>
          </div>
        </div>
        <div className="community_info">
          <MarkG />
          <div className="tooltip">{t("chat_gaming")}
            <span className="tooltiptext">{t("gaming_info")}</span>
          </div>
        </div>
        <div className="community_info">
          <MarkG />
          <div className="tooltip">{t("chat_news")}
            <span className="tooltiptext">{t("news_info")}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
export default Community;
