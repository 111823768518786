import React from 'react';
import './block.css';
import Mark from '../mark/mark';

const BlockName = ({ text }) => (
  <div className="mry__blog-container_block">
    <Mark />
    <div className="mry__blog-container_block-content"><p>{text}</p>
    </div>
  </div>
);
export default BlockName;
